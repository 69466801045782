import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

export default function OurTeam() {
  const [currentIndex, setCurrentIndex] = useState(0); // State to track current index

  const teamMembers = [
    {
      imgSrc: "assets/team-male.jpg",
      fullName: "Hassan Javed",
      designation: "CEO and Founder",
      color:'#517BBD'
    },
    {
      imgSrc: "assets/team-female.jpg",
      fullName: "Sidra Khalid",
      designation: "Head HR and Admin",
      color:'#FBBC16'
    },
    {
      imgSrc: "assets/team-male.jpg",
      fullName: "Raza Fayyaz Siddiqui",
      designation: "Development Manager",
      color:'#34A754'
    },
    {
      imgSrc: "assets/team-male.jpg",
      fullName: "Abdul Rehman",
      designation: "DevOps",
      color:'#103F72'
    },
    {
      imgSrc: "assets/team-male.jpg",
      fullName: "Abdullah Tanveer",
      designation: "Full Stack Dev",
      color:'#E84336'
    },
    {
      imgSrc: "assets/team-male.jpg",
      fullName: "Hamza Khalid",
      designation: "Full Stack Dev",
      color:'#517BBD'
    },
    {
      imgSrc: "assets/team-male.jpg",
      fullName: "Muhammad Hannan",
      designation: "Full Stack Dev",
      color:'#517BBD'
    },
    {
      imgSrc: "assets/team-female.jpg",
      fullName: "Mashal",
      designation: "Full Stack Dev",
      color:'#FBBC16'
    },
    {
      imgSrc: "assets/team-male.jpg",
      fullName: "Ayan",
      designation: "Flutter Developer",
      color:'#34A754'
    },
    {
      imgSrc: "assets/team-male.jpg",
      fullName: "Shahzad Panhwar",
      designation: "Flutter Developer",
      color:'#FBBC16'
    },
    {
      imgSrc: "assets/team-male.jpg",
      fullName: "Abdul Rehman",
      designation: "Sr. Python Developer",
      color:'#34A754'
    },
    {
      imgSrc: "assets/team-female.jpg",
      fullName: "Maryam Zia",
      designation: "Exec. SQAE",
      color:'#103F72'
    },
    {
      imgSrc: "assets/team-male.jpg",
      fullName: "Arham Shafique",
      designation: "Senior SQAE",
      color:'#E84336'
    },
    {
      imgSrc: "assets/team-female.jpg",
      fullName: "Muqaddas Gulzar",
      designation: "Junior SQAE",
      color:'#517BBD'
    },
    {
      imgSrc: "assets/team-female.jpg",
      fullName: "Sidra Khalid",
      designation: "Head HR and Admin",
      color:'#FBBC16'
    },
    {
      imgSrc: "assets/team-male.jpg",
      fullName: "Raza Fayyaz Siddiqui",
      designation: "Development Manager",
      color:'#34A754'
    },
  ];

  // Function to handle moving forward in the carousel
  const nextIndex = () => {
    setCurrentIndex(prevIndex => (prevIndex + 1) % teamMembers.length);
  };

  // Function to handle moving backward in the carousel
  const prevIndex = () => {
    setCurrentIndex(prevIndex => (prevIndex === 0 ? teamMembers.length - 1 : prevIndex - 1));
  };

  // Function to render three members in a row
  const renderMembersInRow = () => {
    const screenSize = window.innerWidth;
    let numberOfMembersToShow;
    if (screenSize < 576) {
      numberOfMembersToShow = 2;
    } else if (screenSize < 992) {
      numberOfMembersToShow = 4;
    } else {
      numberOfMembersToShow = 5;
    }
  
    const membersToShow = [];
    const startIndex = currentIndex % teamMembers.length;
    for (let i = 0; i < numberOfMembersToShow; i++) {
      const index = (startIndex + i) % teamMembers.length;
      membersToShow.push(teamMembers[index]);
    }
    return membersToShow;
  };
  

  return (
    <div className="container-fluid py-5 mb-5 team">
      <div className="container-fluid">
        <div className="text-center mx-auto wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: "600px" }}>
          <h5 className="text-primary">Our Team</h5>
          <h1>Meet our expert Team</h1>
        </div>
        <div className="d-flex justify-content-end pb-5 mb-3">
          <button className="btn btn-team-prev" onClick={prevIndex}><i className="fa-solid fa-arrow-left"></i></button>
          <button className="btn btn-team-next" onClick={nextIndex}><i className="fa-solid fa-arrow-right"></i></button>
        </div>
        <div className="team-carousel wow fadeIn" data-wow-delay=".5s">
          <div className="d-flex justify-content-center">
            {/* Render three visible team members */}
            {renderMembersInRow().map((member, index) => (
              <div key={index} className="rounded team-item mx-md-3 mx-2" style={{ borderTop: `30px solid ${member.color}` }}>
                <div className="team-content" style={{ position: "relative" }}>
                  <div className="team-img-icon">
                    <div className="team-img rounded-circle">
                      <img src={member.imgSrc} className="img-fluid w-100 rounded-circle" style={{ border: `10px solid ${member.color}` }} alt="" />
                    </div>
                    <div className="team-name text-center py-3">
                      <h5 className="">{member.fullName}</h5>
                      <p className="m-0">{member.designation}</p>
                    </div>
                    <div className="team-icon d-flex justify-content-center pb-4">
                      {/* Add social media links */}
                    </div>
                  </div>
                  <div style={{ height: "200px", display: "block", content: "", width: '100%', position: "absolute", zIndex: '0', top: "-102px", background: member.color, clipPath: "polygon(50% 50%, 100% 50%, 50% 100%, 0% 50%)", padding: "60px", opacity: "1" }} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
